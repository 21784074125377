import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { localization } from "@app2/shared/localization/localization";

@Component({
    selector: "action-buttons",
    template: `
        <div class="action buttons action-buttons no-print">
            <hr *ngIf="!inline"/>
            <i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
            <ng-container *ngIf="!loading">
                <ds-button [size]="sizes || (inline ? 'small' : 'large')"
                           type="outlined"
                           id="add-button"
                           test-class="add-button"
                           *ngIf="showAdd"
                           [disabled]="addDisabled"
                           (clicked)="emit(add, $event)">
                    {{ addText }}
                </ds-button>
                <div>
                    <ds-button [size]="sizes || (inline ? 'small' : 'large')"
                               type="delete"
                               test-class="delete-button"
                               *ngIf="showDelete"
                               [disabled]="deleteDisabled"
                               (clicked)="emit(delete, $event)">
                        {{ deleteText }}
                    </ds-button>
                    <ds-button [size]="sizes || (inline ? 'small' : 'large')"
                               type="cancel"
                               test-class="cancel-button"
                               *ngIf="showCancel"
                               [disabled]="cancelDisabled"
                               (clicked)="emit(cancel, $event)">
                        {{ cancelText }}
                    </ds-button>
                    <ds-button [size]="sizes || (inline ? 'small' : 'large')"
                               test-class="save-button"
                               *ngIf="showSave"
                               [disabled]="saveDisabled || saveInProgress"
                               [matTooltip]="saveDisabledTooltipKey | localize"
                               [matTooltipDisabled]="!saveDisabled || !saveDisabledTooltipKey"
                               (clicked)="emit(save, $event)">
                        <i *ngIf="saveInProgress" class="fa fa-spinner fa-spin"></i> {{ saveText }}
                    </ds-button>
                </div>
            </ng-container>
        </div>
    `,
    styles: [`
        ds-button {
            margin-left: 0.25rem;
        }
    `],
})
export class ActionButtonsComponent implements OnChanges {
    @Output() save = new EventEmitter<void>();
    @Output() add = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() cancel = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();

    @Input() saveDisabled: boolean = false;
    @Input() addDisabled: boolean = false;
    @Input() cancelDisabled: boolean = false;
    @Input() deleteDisabled: boolean = false;

    @Input() saveText = localization.getString("SAVE");
    @Input() addText = localization.getString("ADD");
    @Input() cancelText = localization.getString("CANCEL");
    @Input() deleteText = localization.getString("DELETE");

    @Input() saveType: string = "submit";
    @Input() addType: string = "submit";
    @Input() inline: boolean = false;

    // If these inputs are not specified, we attempt to guess whether the buttons should be shown by looking at whether
    // they have subscribers (i.e. if they were specified in the parent's template).
    @Input() showSave: boolean;
    @Input() showAdd: boolean;
    @Input() showCancel: boolean;
    @Input() showDelete: boolean;

    @Input() loading: boolean;
    @Input() sizes: ("small" | "medium" | "large") = "medium";

    @Input() saveInProgress: boolean;
    @Input() saveDisabledTooltipKey: string;

    ngOnChanges(): void {
        this.showSave = this.showSave === undefined ? this.save.observers.length > 0 : this.showSave;
        this.showAdd = this.showAdd === undefined ? this.add.observers.length > 0 : this.showAdd;
        this.showCancel = this.showCancel === undefined ? this.cancel.observers.length > 0 : this.showCancel;
        this.showDelete = this.showDelete === undefined ? this.delete.observers.length > 0 : this.showDelete;
    }

    emit(emitter, event) {
        emitter.emit();
        event.stopPropagation();
    }
}
