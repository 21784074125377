import { Component, EventEmitter, Input, Output } from "@angular/core";
import { importanceOptions } from "@app2/asset/asset-constants";
import { localization } from "@app2/shared/localization/localization";
import { Asset } from "@app2/type-defs/assets/assets-types";

@Component({
    selector: "asset-details-view",
    template: `
        <div class="nice-form">
            <div class="flex-container">
                <div class="asset-details-left-panel">
                    <label (click)="toggleEditing.emit()">{{ 'ASSET_NAME' | localize }}
                        <p test-id="asset-name">{{ asset.name }}</p>
                    </label>

                    <label (click)="toggleEditing.emit()">{{ 'ASSET_TAG' | localize }}
                        <p>{{ asset.tag }}</p>
                    </label>

                    <label (click)="toggleEditing.emit()">{{ 'ASSET_OWNER' | localize }}
                        <p test-id="asset-owner">{{ asset.owner }}</p>
                    </label>

                    <label (click)="toggleEditing.emit()">{{ 'ASSET_IMPORTANCE' | localize }}
                        <p test-id="asset-importance">{{ assetImportance }}</p>
                    </label>
                </div>
                <div>
                    <label class="default-cursor">
                        {{ 'ASSET_LAST_SEEN' | localize }}
                        <p test-id="asset-last-seen">
                            <tooltip-date *ngIf="asset.lastSeen" [for]="asset.lastSeen"></tooltip-date>
                            <span *ngIf="!asset.lastSeen">{{ 'ASSET_UNKNOWN_DATE' | localize }}</span>
                        </p>
                    </label>
                    <label class="default-cursor">
                        {{ 'ASSET_LAST_DATA_SENT' | localize }}
                        <p test-id="asset-last-data-sent">
                            <tooltip-date *ngIf="asset.dataLastSent" [for]="asset.dataLastSent"></tooltip-date>
                            <span *ngIf="!asset.dataLastSent">{{ 'ASSET_UNKNOWN_DATE' | localize }}</span>
                        </p>
                    </label>
                    <label class="default-cursor">
                        {{ 'ASSET_LAST_MODIFIED_ON' | localize }}
                        <p test-id="asset-last-modified-on">
                            <tooltip-date *ngIf="asset.modifiedOn" [for]="asset.modifiedOn"></tooltip-date>
                            <span *ngIf="!asset.modifiedOn">{{ 'ASSET_UNKNOWN_DATE' | localize }}</span>
                        </p>
                    </label>
                    <label class="default-cursor">
                        {{ 'CREATED_ON' | localize }}
                        <p test-id="asset-created-on">
                            <tooltip-date *ngIf="asset.createdOn" [for]="asset.createdOn"></tooltip-date>
                            <span *ngIf="!asset.createdOn">{{ 'ASSET_UNKNOWN_DATE' | localize }}</span>
                        </p>
                    </label>
                </div>
            </div>

            <ng-container *ngIf="asset.description">
                <h1 (click)="toggleEditing.emit()">
                    {{ 'ASSET_DESCRIPTION' | localize }}
                </h1>
                <p class="description-text"
                   (click)="toggleEditing.emit()"
                   test-id="asset-description">{{ asset.description }}</p>
            </ng-container>

            <h1>{{ 'ASSET_LABELS' | localize }}</h1>
            <label-editor [readOnly]="true"
                          [labels]="asset.labels"
                          test-id="asset-labels"></label-editor>

            <h1 (click)="toggleEditing.emit()">{{ 'ASSET_HOSTNAME' | localize }}</h1>
            <section test-id="asset-hostnames">
                <table class="hover-table hostname-table">
                    <thead>
                    <tr>
                        <th class="hover-tab"></th>
                        <th>{{ 'ASSET_HOSTNAME' | localize }}</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="asset.hostnames.length > 0">
                    <tr *ngFor="let hostname of asset.hostnames">
                        <td class="hover-tab"></td>
                        <td>{{ hostname }}</td>
                    </tr>
                    </tbody>
                    <tbody *ngIf="!asset.hostnames.length">
                    <tr>
                        <td class="no-data-text" colspan="2">{{ 'ASSET_NO_HOSTNAME' | localize }}</td>
                    </tr>
                    </tbody>
                </table>
            </section>
            <search-link [queryString]="recentChangesQuery"
                         [newTab]="true"
                         test-id="asset-update-events">
                {{ "VIEW_ASSET_UPDATE_EVENTS" | localize }}
            </search-link>
        </div>
    `,
    styles: `
        @import "/src/styles/colors";

        .asset-details-left-panel {
            flex-grow: 1;
        }

        label p {
            color: $body-font-color;
            font-size: 14px;
            margin-top: 0;
        }

        p.description-text {
            font-size: 14px;
            margin-bottom: 1.25rem;
        }
    `,
})
export class AssetDetailsViewComponent {
    @Input() asset: Asset;
    @Output() toggleEditing = new EventEmitter<void>();

    get recentChangesQuery() {
        return `app_name:"DefenseStorm Audit" AND action:updateAsset AND asset_id:${this.asset.id}`;
    }

    get assetImportance() {
        const i = importanceOptions.find(opt => opt.value === this.asset.importance.toLowerCase());
        const name = localization.getString("ASSET_IMPORTANCE_" + i.key);
        return `${ i.symbol } ${ name }`;
    }
}
