import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UserClientService } from "@app2/clients/user-client.service";
import { CyberRiskReadinessService } from "@app2/dashboards/cyber-risk-readiness/cyber-risk-readiness.service";
import { FeaturesService } from "@app2/shared/services/features.service";
import { RouterService } from "@app2/shared/services/router.service";
import { InfoNoteThreatLevel } from "@app2/type-defs/metadata/metadata-types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "navigation-header-bar",
    template: `
        <nav class="top-bar" role="navigation">
            <div class="flex-container">
                <div class="title-area">
                    <img src="assets/icons/ds-header-label.svg" class="branding-logo" test-id="branding-logo"/>
                </div>
                <div *ngIf="authenticated" class="action-items-section">
                    <button *ngIf="cyberRiskReadinessBannerEnabled || cyberRiskReadinessDashboardEnabled"
                            class="cyber-risk-readiness-button"
                            (click)="cyberRiskReadinessDashboardEnabled ? navigateToHome() : navigateToDashboards()">
                        <img class="crr-ring-logo" src="assets/images/grid-crr-ring.svg"/>
                        {{ "CYBER_RISK_READINESS" | localize }}
                    </button>
                    <ng-container *featureGate="'infoPanel'">
                        <ng-container *dsPermissionsRequired="'TRAC_USER'">
                            <i *ngIf="!currentThreatLevel" class="fa fa-spinner fa-spin spinner"></i>
                            <ds-button *ngIf="currentThreatLevel"
                                       [attr.id]="'toggle-info-panel-button-threat-level-' + currentThreatLevelNumber"
                                       type="black-link"
                                       size="medium"
                                       class="info-panel-button"
                                       (clicked)="toggleInfoPanel.emit()"
                                       test-id="info-panel-button">
                                <threat-level-icon [threatLevel]="currentThreatLevel">
                                </threat-level-icon>
                            </ds-button>
                        </ng-container>
                    </ng-container>
                    <a href="https://kb.defensestorm.com/help" target="_blank" rel="noopener noreferrer"
                       test-id="support-link">
                        <i [matTooltip]="'HELP' | localize" class="far fa-circle-question"></i>
                    </a>
                    <a [href]="freshdeskRedirectUrl" target="_blank" rel="noopener noreferrer"
                       test-id="connect-link">
                        <i [matTooltip]="'MODULE_CONNECT' | localize" class="far fa-globe-pointer"></i>
                    </a>
                    <navbar-user-menu></navbar-user-menu>
                </div>
            </div>
        </nav>
    `,
    styles: `
        @import "/src/styles/colors";
        @import "/src/styles/settings";

        .flex-container {
            justify-content: space-between;
            align-items: center;
            height: 100%;
        }

        nav.top-bar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: $nav-header-bar-height;
            padding: 0 24px;
            background-color: $content-bg-color;
            border-bottom: 1px solid $gray-4;
        }

        .branding-logo {
            height: 20px;
        }

        .action-items-section {
            display: flex;
            align-items: center;

            .spinner {
                color: $gray-7;
                height: 32px;
                margin-right: 32px;
                padding-top: 8px;
            }

            a {
                margin-right: 32px;
            }

            i {
                color: $gray-9;
            }

            .info-panel-button {
                margin: 0 8px 8px 0;

                button {
                    display: flex;
                }
            }

            .cyber-risk-readiness-button {
                position: relative;
                top: 0;
                margin-right: 32px;
                font-size: 1rem;
                height: 100%;
                overflow: hidden;
                background-color: $ds-blue-9;
                padding-top: 0.5rem;
                padding-left: 60px;

                .crr-ring-logo {
                    position: absolute;
                    height: 140%;
                    left: 5px;
                    top: -20%;
                }
            }
        }
    `,
})
export class NavigationHeaderBarComponent {
    @Input() authenticated: boolean;
    @Input() currentThreatLevel: InfoNoteThreatLevel;
    @Output() toggleInfoPanel = new EventEmitter();

    freshdeskRedirectUrl: string;
    currentThreatLevelNumber: number;
    cyberRiskReadinessBannerEnabled: boolean;
    cyberRiskReadinessDashboardEnabled: boolean;

    constructor(userClientService: UserClientService,
                private routerService: RouterService,
                private crrService: CyberRiskReadinessService,
                private featuresService: FeaturesService) {
        this.freshdeskRedirectUrl = userClientService.getFreshdeskRedirectUrl();
        this.currentThreatLevelNumber = Object.keys(InfoNoteThreatLevel).indexOf(this.currentThreatLevel) + 1;
        this.featuresService.getFeatures$()
            .pipe(untilDestroyed(this))
            .subscribe(features => {
                this.cyberRiskReadinessBannerEnabled = features.cyberRiskReadinessBanner;
                this.cyberRiskReadinessDashboardEnabled = features.cyberRiskReadinessDashboard;
            });
    }

    navigateToDashboards() {
        this.crrService.openBanner();
        this.routerService.navigate(["/dashboards/default"]);
    }

    navigateToHome() {
        this.routerService.navigate(["/home"]);
    }
}
