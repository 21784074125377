import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { NotificationType } from "@app2/shared/services/notifications.service";

type NotificationData = { message: string, type: NotificationType, action: string, iconName?: string };

@Component({
    selector: "notification-message",
    template: `
        <notification-icon [type]="data.type" [iconName]="data.iconName"></notification-icon>
        <span class="flex-grow">{{ data.message }}</span>
        <button *ngIf="data.action"
                class="link notification-message-action"
                (click)="action()">
            {{ data.action | localize }}
        </button>
        <span class="far fa-times notification-close" (click)="close()"></span>
    `,
    styles: [`
        .notification-close {
            padding-left: 16px;
            cursor: pointer;
        }

        .flex-grow {
          flex-grow: 1;
        }
    `],
    host: {
        class: "notification-message",
    },
})
export class NotificationMessageComponent {
    constructor(public snackBarRef: MatSnackBarRef<NotificationMessageComponent>,
                @Inject(MAT_SNACK_BAR_DATA) public data: NotificationData) {
    }

    action(): void {
        this.snackBarRef.dismissWithAction();
    }

    close(): void {
        this.snackBarRef.dismiss();
    }
}
