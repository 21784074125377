import { DropdownOption } from "@app2/shared/predicate-dropdown-menu.component";

export enum RiskStateType {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    ALL = "ALL",
}

export enum RiskModuleType {
    SYSTEMS_AND_APPLICATIONS = "SYSTEMS_AND_APPLICATIONS",
    INFORMATION_SECURITY = "INFORMATION_SECURITY",
    THIRD_PARTY_RISK = "THIRD_PARTY_RISK",
}

export const moduleOptions: DropdownOption<RiskModuleType>[] = [
    { value: "ALL", key: "ALL" },
    { value: RiskModuleType.SYSTEMS_AND_APPLICATIONS, key: "RISK_MODULE_SYSTEMS_AND_APPLICATIONS" },
    { value: RiskModuleType.INFORMATION_SECURITY, key: "RISK_MODULE_INFORMATION_SECURITY" },
    { value: RiskModuleType.THIRD_PARTY_RISK, key: "RISK_MODULE_THIRD_PARTY_RISK" },
];

export type WithoutReadonlyFields<T> =
    Omit<T, "id" | "orgId" | "publicId" | "createdOn" | "createdBy" | "lastModifiedOn" | "lastModifiedBy" | "deleted" |
        "copiedFromLibraryId" | "goLiveDate">

export interface OperationalRisk {
    id: uuid;
    orgId: uuid;
    publicId: number;
    name: string;
    description: string;
    deleted: boolean;
    createdOn: date;
    createdBy: uuid;
    lastModifiedOn: date;
    lastModifiedBy: uuid;
    keyRisk: boolean;
    showInPreview: boolean;
    state: RiskStateType;
    mitigationPlan: string;
    businessUnitIds: uuid[];
    riskOwnerId: uuid;
    threatDetails: RiskThreatDetails;
    riskThreatDetails: RiskThreatDetails[];
    riskAssessmentDetails: RiskAssessmentDetails;
    watcherIds: uuid[];
    lineItemIds: uuid[];
    auditIds: uuid[];
    externalPolicyIds: uuid[];
    modules: RiskModuleType[];
    tagIds: uuid[];
    copiedFromLibraryId: uuid;
    ermConnections: ErmConnectionType[];
    riskResponse: RiskResponseType;
    isException: boolean;
}

export enum RiskContextualScoreType {
    RISK_CONTEXTUAL_SCORE = "RISK_CONTEXTUAL_SCORE",
    CONTROL_CONTEXTUAL_SCORE = "CONTROL_CONTEXTUAL_SCORE"
}

export interface OperationalRiskContextualScore {
    orgId: uuid;
    systemId: uuid;
    riskId: uuid;
    likelihood: RiskAssessmentType;
    likelihoodNote: string;
    potentialImpact: RiskAssessmentType;
    potentialImpactNote: string;
    inherentRisk: RiskAssessmentType;
    inherentRiskNote: string;
    controlStrength: RiskAssessmentType;
    controlStrengthNote: string;
    residualRisk: RiskAssessmentType;
    residualRiskNote: string;
    riskDirection: RiskAssessmentType;
    riskDirectionNote: string;
    recommendedScores: RecommendedRiskScores;
    isBaselineScore: boolean;
}

export interface InternalControlContextualScore {
    orgId: uuid;
    systemId: uuid;
    riskId: uuid;
    controlId: uuid;
    strengthOfControl: RiskAssessmentType;
    strengthOfControlNote: string;
    isBaselineScore: boolean;
}

export enum ErmConnectionType {
    STRATEGIC = "STRATEGIC",
    REPUTATIONAL = "REPUTATIONAL",
    COMPLIANCE = "COMPLIANCE",
    FINANCIAL = "FINANCIAL",
    CREDIT = "CREDIT",
}

export enum RiskResponseType {
    ACCEPT = "ACCEPT",
    AVOID = "AVOID",
    TRANSFER = "TRANSFER",
    MITIGATE = "MITIGATE",
}

export interface RiskThreatDetails {
    riskEventType: string;
    riskEventTypeSubcategory: string;
    causeCategory: string;
    causeSubcategory: string;
    consequence: string;
    consequenceSubcategory: string;
    ermConnections: ErmConnectionType[];
    riskResponse: RiskResponseType;
}

export enum InherentAndResidualRiskAssessmentType {
    INSIGNIFICANT = "INSIGNIFICANT",
    LOW = "LOW",
    MODERATE_IAR = "MODERATE",
    HIGH = "HIGH",
    EXTREME = "EXTREME",
}

export enum QualityOfRiskManagementRiskAssessmentType {
    INEFFECTIVE = "INEFFECTIVE",
    WEAK = "WEAK",
    MODERATE_QOR = "MODERATE",
    STRONG = "STRONG",
    COMPLETE = "COMPLETE",
}

export enum DirectionRiskAssessmentType {
    DECREASING_RAPIDLY = "DECREASING_RAPIDLY",
    DECREASING = "DECREASING",
    STABLE = "STABLE",
    INCREASING = "INCREASING",
    INCREASING_RAPIDLY = "INCREASING_RAPIDLY",
}

export type RiskAssessmentType =
    InherentAndResidualRiskAssessmentType
    | QualityOfRiskManagementRiskAssessmentType
    | DirectionRiskAssessmentType;

export const RiskAssessmentType = {
    ...InherentAndResidualRiskAssessmentType,
    ...QualityOfRiskManagementRiskAssessmentType,
    ...DirectionRiskAssessmentType,
};

export type RiskAssessmentTypeWeights =
    Record<InherentAndResidualRiskAssessmentType, number> |
    Record<QualityOfRiskManagementRiskAssessmentType, number> |
    Record<DirectionRiskAssessmentType, number>

export type RiskAssessmentTypeRecommendations = {
    inherentRisk: RiskAssessmentType,
    managementQuality: RiskAssessmentType,
    residualRisk: RiskAssessmentType,
    riskDirection: RiskAssessmentType,
}

export interface RiskAssessmentDetails {
    potentialImpact: InherentAndResidualRiskAssessmentType;
    likelihood: InherentAndResidualRiskAssessmentType;
    inherentRisk: InherentAndResidualRiskAssessmentType;
    controlStrength: QualityOfRiskManagementRiskAssessmentType;
    residualRisk: InherentAndResidualRiskAssessmentType;
    riskDirection: DirectionRiskAssessmentType;
    // Notes
    potentialImpactNote: string;
    likelihoodNote: string;
    inherentRiskNote: string;
    controlStrengthNote: string;
    residualRiskNote: string;
    riskDirectionNote: string;
}

export enum RiskScoreNameType {
    POTENTIAL_IMPACT = "POTENTIAL_IMPACT",
    LIKELIHOOD = "LIKELIHOOD",
    INHERENT_RISK = "INHERENT_RISK",
    CONTROL_STRENGTH = "CONTROL_STRENGTH",
    RESIDUAL_RISK = "RESIDUAL_RISK",
    RISK_DIRECTION = "RISK_DIRECTION",
    DESIGN_EFFECTIVENESS = "DESIGN_EFFECTIVENESS",
    OPERATING_EFFECTIVENESS = "OPERATING_EFFECTIVENESS",
    OVERALL_SCORE = "OVERALL_SCORE",
    DATA_SENSITIVITY = "DATA_SENSITIVITY",
    BUSINESS_CRITICALITY = "BUSINESS_CRITICALITY",
}

export interface RiskScoreHistoryLog {
    riskId: uuid;
    orgId: uuid;
    riskScoreName: RiskScoreNameType;
    updatedOn: date;
    updatedBy: uuid;
    oldRiskType: RiskAssessmentType;
    newRiskType: RiskAssessmentType;
    noteAdded: string;
}

export interface RiskContextualScoreHistoryRequest {
    systemId: uuid;
    riskId: uuid;
    controlId?: uuid;
    scoreName: RiskScoreNameType;
    objectType: RiskObjectType;
}

export interface RiskFile {
    id: uuid;
    orgId: uuid;
    riskId: uuid;
    filename: string;
    size: number;
    contentType: string;
    createdOn: date;
    createdBy: uuid;
}

export interface StartRiskFileUploadResponse {
    fileId: uuid;
    url: string;
}

export enum RiskLogType {
    CREATED = "CREATED",
    COPIED = "COPIED",
    STATE = "STATE",
    NAME = "NAME",
    DESCRIPTION = "DESCRIPTION",
    MITIGATION_PLAN = "MITIGATION_PLAN",
    KEY_RISK = "KEY_RISK",
    WATCHER_IDS = "WATCHER_IDS",
    BUSINESS_UNIT = "BUSINESS_UNIT",
    BUSINESS_UNITS = "BUSINESS_UNITS",
    RISK_OWNER = "RISK_OWNER",
    THREAT_DETAILS = "THREAT_DETAILS",
    FILES = "FILES",
    LINE_ITEM_IDS = "LINE_ITEM_IDS",
    INTERNAL_CONTROLS = "INTERNAL_CONTROLS",
    TAG_IDS = "TAG_IDS",
    AUDIT = "AUDIT",
    EXTERNAL_POLICY = "EXTERNAL_POLICY",
    RELATED_RISK = "RELATED_RISK",
    MODULE_TYPE = "MODULE_TYPE",
    LINKED_RISKS = "LINKED_RISKS",
    LINKED_CONTROLS = "LINKED_CONTROLS",
    LINKED_SYSTEMS = "LINKED_SYSTEMS",
    SYSTEM_TYPE = "SYSTEM_TYPE",
    DATA_LOCATION = "DATA_LOCATION",
    VERSION_NUMBER = "VERSION_NUMBER",
    GO_LIVE_DATE = "GO_LIVE_DATE",
    SYSTEM_ADMIN = "SYSTEM_ADMIN",
    VENDOR = "VENDOR",
    SENSITIVITY_QUESTIONS = "SENSITIVITY_QUESTIONS",
    CRITICALITY_QUESTIONS = "CRITICALITY_QUESTIONS",
    PARAMETER_QUESTIONS = "PARAMETER_QUESTIONS",
    PARAMETER_AUTH_PEOPLE = "PARAMETER_AUTH_PEOPLE",
    ACCESS_QUESTIONS = "ACCESS_QUESTIONS",
    ACCESS_AUTH_PEOPLE = "ACCESS_AUTH_PEOPLE",
    ACCESS_COMMENTS = "ACCESS_COMMENTS",
    SECURITY_QUESTIONS = "SECURITY_QUESTIONS",
    SECURITY_COMMENTS = "SECURITY_COMMENTS",
    PARAMETER_COMMENTS = "PARAMETER_COMMENTS",
    GENERAL_AUDIT = "GENERAL_AUDIT",
    ERM_CONNECTIONS = "ERM_CONNECTIONS",
    RISK_RESPONSE = "RISK_RESPONSE",
    ACTIVE_RISK_SCHEDULE = "ACTIVE_RISK_SCHEDULE",
    ASSET_TYPE = "ASSET_TYPE",
}

export interface RiskLog {
    id: uuid;
    orgId: uuid;
    riskId: uuid;
    createdOn: date;
    createdBy: uuid;
    type: RiskLogType;
    oldValue: any;
    newValue: any;
}

export interface RiskCopyToOrgResponse {
    copiedIds: uuid[];
    newIds: uuid[];
}

export enum InternalControlType {
    PREVENTATIVE = "PREVENTATIVE",
    CORRECTIVE = "CORRECTIVE",
    DETECTIVE = "DETECTIVE",
}

export enum InternalControlCategory {
    SECURITY_AND_AUTHENTICATION = "SECURITY_AND_AUTHENTICATION",
    STANDARDS_AND_DOCUMENTATION = "STANDARDS_AND_DOCUMENTATION",
    GOVERNANCE = "GOVERNANCE",
    ROLES_AND_RESPONSIBILITIES = "ROLES_AND_RESPONSIBILITIES",
    CULTURE_AND_TRAINING = "CULTURE_AND_TRAINING",
    ASSURANCE_AND_REMEDIATION = "ASSURANCE_AND_REMEDIATION",
    REDUNDANCY = "REDUNDANCY",
}

export interface InternalControlDetails {
    type: InternalControlType;
    category: InternalControlCategory;
    manual: boolean;
}

export interface InternalControlScores {
    designEffectiveness: RiskAssessmentType;
    operatingEffectiveness: RiskAssessmentType;
    overallScore: QualityOfRiskManagementRiskAssessmentType;
    designEffectivenessNote: string;
    operatingEffectivenessNote: string;
    overallScoreNote: string;
}

export interface InternalControl {
    id: uuid;
    orgId: uuid;
    publicId: number;
    name: string;
    description: string;
    deleted: boolean;
    createdOn: date;
    createdBy: uuid;
    lastModifiedOn: date;
    lastModifiedBy: uuid;
    keyControl: boolean;
    showInPreview: boolean;
    state: RiskStateType;
    businessUnitIds: uuid[];
    controlOwnerId: uuid;
    controlDetails: InternalControlDetails;
    controlScores: InternalControlScores;
    watcherIds: uuid[];
    lineItemIds: uuid[];
    modules: RiskModuleType[];
    auditIds: uuid[];
    externalPolicyIds: uuid[];
    tagIds: uuid[];
    copiedFromLibraryId: uuid;
}

export interface RiskSystem {
    id: uuid;
    orgId: uuid;
    publicId: number;
    name: string;
    description: string;
    deleted: boolean;
    createdOn: date;
    createdBy: uuid;
    lastModifiedOn: date;
    lastModifiedBy: uuid;
    state: RiskStateType;
    dataLocation: string;
    versionNumber: string;
    mitigationPlan: string;
    goLiveDate: date;
    businessUnitIds: uuid[];
    businessOwnerId: uuid;
    systemAdminId: uuid;
    vendor: uuid;
    systemScores: RiskSystemScores;
    watcherIds: uuid[];
    lineItemIds: uuid[];
    auditIds: uuid[];
    externalPolicyIds: uuid[];
    tagIds: uuid[];
    copiedFromLibraryId: uuid;
    sensitivityQuestions: Record<string, boolean>;
    criticalityQuestions: Record<string, boolean>;
    parameterQuestions: Record<string, boolean>;
    parameterAuthPeople: uuid[];
    parameterComments: string;
    accessQuestions: Record<string, boolean>;
    accessAuthPeople: uuid[];
    accessComments: string;
    securityQuestions: Record<string, boolean>;
    securityComments: string;
    assetTypeIds: uuid[];
}

export interface RiskSystemScores {
    dataSensitivity: InherentAndResidualRiskAssessmentType;
    businessCriticality: InherentAndResidualRiskAssessmentType;
    inherentRisk: InherentAndResidualRiskAssessmentType;
    controlStrength: QualityOfRiskManagementRiskAssessmentType;
    residualRisk: InherentAndResidualRiskAssessmentType;
    riskDirection: DirectionRiskAssessmentType;

    // Notes:
    dataSensitivityNote: string;
    businessCriticalityNote: string;
    inherentRiskNote: string;
    controlStrengthNote: string;
    residualRiskNote: string;
    riskDirectionNote: string;
}

export interface GetSuggestedRisksResponse {
    suggestedLibRisks: OperationalRisk[];
    suggestedRisks: OperationalRisk[];
}

export interface GetSuggestedControlLinksResponse {
    suggestedLibControls: InternalControl[];
    suggestedControls: InternalControl[];
}

export interface GetSuggestedSystemsResponse {
    suggestedLibSystems: RiskSystem[];
    suggestedSystems: RiskSystem[];
}

export enum RiskUserDefinedListType {
    AUDIT_COVERAGE = "AUDIT_COVERAGE",
    BUSINESS_UNIT = "BUSINESS_UNIT",
    EXTERNAL_POLICY = "EXTERNAL_POLICY",
    RISK_OWNER = "RISK_OWNER",
    RISK_VENDOR = "RISK_VENDOR",
    SYSTEM_ASSET_TYPE = "SYSTEM_ASSET_TYPE",
}

export interface RiskUserDefinedListValue {
    id: uuid;
    orgId: uuid;
    type: RiskUserDefinedListType;
    value: string;
}

export interface RiskOwner extends RiskUserDefinedListValue {
    type: RiskUserDefinedListType.RISK_OWNER;
    isGridUser: boolean;
}

export enum RiskObjectType {
    RISK = "RISK",
    INTERNAL_CONTROL = "INTERNAL_CONTROL",
    RISK_SYSTEM = "RISK_SYSTEM",
}

export enum AssessmentType {
    RISK = "RISK",
    INVENTORY = "INVENTORY"
}

export type RiskAssessment = IncompleteRiskAssessment | CompletedRiskAssessment;

export type Assessment = RiskAssessment | InventoryAssessment;

interface BaseRiskAssessment {
    id: uuid;
    orgId: uuid;
    name: string;
    createdOn: date;
    createdBy: uuid;
    lastModifiedOn: date;
    lastModifiedBy: uuid;
    deleted: boolean;
    inherentRisk: RiskAssessmentType;
    managementQuality: RiskAssessmentType;
    residualRisk: RiskAssessmentType;
    riskDirection: RiskAssessmentType;
    executiveSummary: string;
    filters: RiskAssessmentFilters;
    modules: RiskModuleType[];
    type: AssessmentType.RISK;
}

export interface IncompleteRiskAssessment extends BaseRiskAssessment {
    completed: false;
}

export interface CompletedRiskAssessment extends BaseRiskAssessment {
    completed: true;
    exported: boolean;
    completedDataSnapshot: RiskAssessmentDataSnapshot;
}

export interface RiskAssessmentDataSnapshot {
    risks: RiskSnapshot[];
    policyCount: number;
    controlCount: number;
    complianceCount: number;
    internalControlCount: number;
}

export interface RiskSnapshot {
    publicId: number;
    name: string;
    inherentRisk: RiskAssessmentType;
    inherentRiskNote?: string;
    controlStrength: RiskAssessmentType;
    controlStrengthNote?: string;
    residualRisk: RiskAssessmentType;
    residualRiskNote?: string;
    riskDirection: RiskAssessmentType;
    riskDirectionNote?: string;
    lineItemIds: uuid[];
    riskOwnerId: uuid;
    /** @deprecated **/
    riskEventType: string;
    potentialImpact: RiskAssessmentType;
    likelihood: RiskAssessmentType;
}

export interface RiskAssessmentFilters {
    threatDetails: RiskThreatDetailsFilters;
    ownerIds: uuid[];
    businessUnitIds: uuid[];
    auditIds: uuid[];
    relatedPolicyIds: uuid[];
    tagIds: uuid[];
    exceptionsFilter: AssessmentExceptionsFilter;
}

export interface InventoryAssessment {
    id: uuid;
    orgId: uuid;
    name: string;
    createdOn: date;
    createdBy: uuid;
    lastModifiedOn: date;
    lastModifiedBy: uuid;
    deleted: boolean;
    completed: boolean;
    completedOn: date;
    dataSensitivity: RiskAssessmentType;
    businessCriticality: RiskAssessmentType;
    inherentRisk: RiskAssessmentType;
    controlStrength: RiskAssessmentType;
    residualRisk: RiskAssessmentType;
    riskDirection: RiskAssessmentType;
    executiveSummary: string;
    exported: boolean;
    dataSnapshot?: InventoryAssessmentDataSnapshot;
    filters: InventoryAssessmentFilters;
    type: AssessmentType.INVENTORY;
}

export interface InventoryAssessmentDataSnapshot {
    systems: RiskSystemSnapshot[];
}

export interface RiskSystemSnapshot {
    id: uuid;
    publicId: number;
    name: string;
    dataSensitivity: RiskAssessmentType;
    businessCriticality: RiskAssessmentType;
    inherentRisk: RiskAssessmentType;
    controlStrength: RiskAssessmentType;
    residualRisk: RiskAssessmentType;
    riskDirection: RiskAssessmentType;
    lineItemIds: uuid[];
    businessOwnerId: uuid;
}

export interface InventoryAssessmentFilters {
    assetTypeIds: uuid[];
    businessOwnerIds: uuid[];
    businessUnitIds: uuid[];
    vendorIds: uuid[];
    auditIds: uuid[];
    relatedPolicyIds: uuid[];
    tagIds: uuid[];
}

export interface InventoryAssessmentFiltersSelection {
    assetTypesSelection: Record<uuid, boolean>;
    businessOwnerSelection: Record<uuid, boolean>;
    businessUnitSelection: Record<uuid, boolean>;
    vendorsSelection: Record<uuid, boolean>;
    auditSelection: Record<uuid, boolean>;
    policySelection: Record<uuid, boolean>;
    tagSelection: Record<uuid, boolean>;
}

export interface RiskScoreActivityLogData extends RiskAssessmentDetails, InternalControlScores, RiskSystemScores {

}

export interface RiskThreatTypes {
    eventTypes: Record<string, string[]>;
    causes: Record<string, string[]>;
    consequences: Record<string, string[]>;
}

export enum RiskThreatType {
    EVENT_TYPE = "EVENT_TYPE",
    CAUSE = "CAUSE",
    CONSEQUENCE = "CONSEQUENCE",
}

export interface RiskThreatDetailsFilters extends RiskThreatDetails {
    riskEventTypes: string[];
    riskCauses: string[];
    riskConsequences: string[];
}

export interface RecommendedRiskScoreInfo {
    recommendedScore: RiskAssessmentType;
    warningKey: string;
    warningExplanationKey: string;
    warningExplanationArgs: string[];
}

export enum ActiveRiskScheduleUnit {
    YEAR = "YEAR",
    MONTH = "MONTH",
    DAY = "DAY",
}

export interface ActiveRiskSchedule {
    dueDate: date,
    previousReviewPeriodStart: date,
    previousReviewPeriodEnd: date,
    dueDateAfterReview: date,
    scheduleInterval: number,
    scheduleUnit: ActiveRiskScheduleUnit,
    timeZoneId: string,
    watchers: uuid[],
    reviewedOn: date,
    canReview: boolean,
}

export interface ActiveRiskScheduleRequest {
    dueDate: date,
    scheduleInterval: number,
    scheduleUnit: ActiveRiskScheduleUnit,
    timeZoneId: string,
    watchers: uuid[],
    riskObjectId: uuid,
    riskObjectType: RiskObjectType,
}

export enum RiskScheduleReviewStatus {
    EARLY = "EARLY",
    ALMOST_DUE = "ALMOST-DUE",
    LATE = "LATE",
}

export enum RiskScheduleLogType {
    ADDED = "ADDED",
    EDITED = "EDITED",
    DELETED = "DELETED",
    MARKED_REVIEWED = "MARKED_REVIEWED",
}

export enum ExceptionsFilter {
    ALL = "ALL",
    ONLY_EXCEPTIONS = "ONLY_EXCEPTIONS",
    EXCLUDE_EXCEPTIONS = "EXCLUDE_EXCEPTIONS",
}

export const exceptionsFilterOptions: DropdownOption<ExceptionsFilter>[] = [
    { value: "ALL", key: "ALL" },
    { value: "ONLY_EXCEPTIONS", key: "RISK_ONLY_EXCEPTIONS_FILTER" },
    { value: "EXCLUDE_EXCEPTIONS", key: "RISK_EXCLUDE_EXCEPTIONS_FILTER" },
];

export enum AssessmentExceptionsFilter {
    INCLUDE_EXCEPTIONS = "INCLUDE_EXCEPTIONS",
    EXCLUDE_EXCEPTIONS = "EXCLUDE_EXCEPTIONS",
    ONLY_EXCEPTIONS = "ONLY_EXCEPTIONS",
}

export const assessmentsExceptionsFilterOptions: DropdownOption<AssessmentExceptionsFilter>[] = [
    { value: AssessmentExceptionsFilter.INCLUDE_EXCEPTIONS, key: "ASSESSMENT_INCLUDE_EXCEPTIONS_FILTER" },
    { value: AssessmentExceptionsFilter.EXCLUDE_EXCEPTIONS, key: "ASSESSMENT_EXCLUDE_EXCEPTIONS_FILTER" },
    { value: AssessmentExceptionsFilter.ONLY_EXCEPTIONS, key: "ASSESSMENT_ONLY_EXCEPTIONS_FILTER" }
];

export interface RecommendedRiskScores {
    inherentRisk: RiskAssessmentType;
    noInherentRiskReason: NoRecommendedInherentRiskReason;
    controlStrength: RiskAssessmentType;
    noControlStrengthReason: NoRecommendedControlStrengthReason;
    residualRisk: RiskAssessmentType;
    noResidualRiskScoreReason: NoRecommendedResidualRiskScoreReason;
}

export enum NoRecommendedInherentRiskReason {
    MISSING_LIKELIHOOD = "MISSING_LIKELIHOOD",
    MISSING_POTENTIAL_IMPACT = "MISSING_POTENTIAL_IMPACT",
    MISSING_LIKELIHOOD_AND_POTENTIAL_IMPACT = "MISSING_LIKELIHOOD_AND_POTENTIAL_IMPACT",
    MISSING_DATA_SENSITIVITY = "MISSING_DATA_SENSITIVITY",
    MISSING_BUSINESS_CRITICALITY = "MISSING_BUSINESS_CRITICALITY",
    MISSING_DATA_SENSITIVITY_AND_BUSINESS_CRITICALITY = "MISSING_DATA_SENSITIVITY_AND_BUSINESS_CRITICALITY"
}

export enum NoRecommendedControlStrengthReason {
    MISSING_LINKED_CONTROLS = "MISSING_LINKED_CONTROLS",
    MISSING_LINKED_CONTROL_OVERALL_SCORE = "MISSING_LINKED_CONTROL_OVERALL_SCORE"
}

export enum NoRecommendedResidualRiskScoreReason {
    MISSING_INHERENT_RISK = "MISSING_INHERENT_RISK",
    MISSING_CONTROL_STRENGTH = "MISSING_CONTROL_STRENGTH",
    MISSING_INHERENT_RISK_AND_CONTROL_STRENGTH = "MISSING_INHERENT_RISK_AND_CONTROL_STRENGTH"
}

export interface RiskAssessmentFiltersSelection {
    riskOwnerSelection: Record<uuid, boolean>;
    businessUnitSelection: Record<uuid, boolean>;
    auditSelection: Record<uuid, boolean>;
    policySelection: Record<uuid, boolean>;
    tagSelection: Record<uuid, boolean>;
    ermConnectionsSelection: Partial<Record<ErmConnectionType, boolean>>;
    threatDetailsFilters: RiskThreatDetailsFilters;
    selectedExceptionsFilter: AssessmentExceptionsFilter;
}
