import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { OrgsService } from "@app2/account/orgs.service";
import { WindowService } from "@app2/shared/services/window.service";
import { OrgSummary } from "@app2/type-defs/user/user-types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, Observable } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import { animate, keyframes, style, transition, trigger } from "@angular/animations";
import { byName } from "@app2/util/util";

@UntilDestroy()
@Component({
    selector: "navbar-org-selector",
    template: `
        <div class="org-badge">
            <span class="org-letter"
                  [matTooltip]="currentOrg.name"
                  [matTooltipDisabled]="leftBarVisible">
                {{ currentOrg.name.charAt(0) }}
            </span>
        </div>
        <div *ngIf="leftBarVisible"
             class="org-name-container"
             @toggleContent>

            <span *ngIf="(authorizedOrgs$ | async).length === 0"
                  class="org-name extra-margin-left">{{ currentOrg.name }}</span>

            <pr-dropdown *ngIf="(authorizedOrgs$ | async).length > 0"
                         test-id="dropdown">
                <div pr-dropdown-trigger class="link-dropdown" (click)="focusText()">
                    <span class="org-details">
                        <span class="org-name">{{ currentOrg.name }}</span>
                        <i class="far fa-chevron-down"></i>
                    </span>
                </div>
                <ul pr-dropdown-body class="overflow-auto">
                    <li class="no-hover" (click)="$event.stopPropagation()">
                        <input #searchTermField
                               type="text"
                               [formControl]="searchTerm"
                               [placeholder]="'BASE_SEARCH' | localize"/>
                        <button class="search-button" type="submit">
                            <i class="far fa-search search-button"></i>
                        </button>
                    </li>
                    <li *ngFor="let org of filteredOrgs"
                        [ngClass]="{'active': currentOrg?.id === org.id }"
                        class="pr-dropdown-flex-row">
                        <a (click)="selectOrg(org.id)"
                           class="fill-available-space">
                            <i class="far fa-check"></i>
                            <i [class.fa-house]="org.id === homeOrg.id" class="far fa-fw"></i>
                            {{ org.name }}
                        </a>
                        <a [href]="getOrgHref(org.id)"
                           target="_blank" rel="noopener noreferrer">
                            <i class="far fa-external-link visible-icon padded"></i>
                        </a>
                    </li>
                </ul>
            </pr-dropdown>
        </div>
    `,
    styles: `
        @import "/src/styles/colors";
        @import "/src/styles/settings";

        :host {
            display: flex;
        }

        .org-badge {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $nav-header-org-badge-height;
            min-width: $nav-header-org-badge-height;
            height: $nav-header-org-badge-height;
            border-radius: 8px;
            background-color: $lara-orange;

            .org-letter {
                font-size: 1.25rem;
                font-weight: $font-weight-bold;
                color: $gray-1;
            }
        }

        .org-name-container {
            display: flex;
            align-items: center;
        }

        .extra-margin-left {
            margin-left: 8px;
        }

        pr-dropdown {
            margin: 4px 8px 0 8px;
        }

        .fa-fw {
            margin-left: 4px;
        }

        .org-name {
            font-size: 1rem;
            font-weight: $font-weight-demibold;
        }

        .fa-chevron-down {
            margin-left: 8px;
            font-size: 0.75rem;
        }

        .fa-house {
            color: $gray-7 !important;
        }
    `,
    animations: [
        trigger("toggleContent", [
            transition(":enter", animate("150ms", keyframes([
                style({ display: "none", offset: 0 }),
            ]))),
        ]),
    ],
})
export class NavbarOrgSelectorComponent {
    @Input() leftBarVisible: boolean;

    currentOrg: OrgSummary;
    filteredOrgs: OrgSummary[];
    homeOrg: OrgSummary;
    authorizedOrgs$: Observable<OrgSummary[]>;

    @ViewChild("searchTermField")
    private searchTermField: ElementRef;
    searchTerm = new FormControl("");

    constructor(private readonly orgsService: OrgsService,
                private router: Router,
                private windowService: WindowService) {
        this.authorizedOrgs$ = orgsService.getAuthorizedOrgs$();

        const allOrgs$: Observable<OrgSummary[]> = orgsService.getOrgsInfo$()
            .pipe(
                // Ignore events until we are correctly setup
                filter(({ homeOrg }) => !!homeOrg),
                map(({ homeOrg, currentOrg, authorizedOrgs }) => {
                    this.currentOrg = currentOrg;
                    this.homeOrg = homeOrg;
                    return [homeOrg, ...authorizedOrgs].sort(byName());
                }),
            );

        const searchTerm$: Observable<string> = this.searchTerm.valueChanges.pipe(startWith(""));

        combineLatest([allOrgs$, searchTerm$])
            .pipe(map(([orgs, searchTerm]) =>
                orgs.filter(org => org.name.toLowerCase().includes(searchTerm.toLowerCase()))))
            .pipe(untilDestroyed(this))
            .subscribe(orgs => this.filteredOrgs = orgs);
    }

    focusText() {
        setTimeout(() => this.searchTermField.nativeElement.focus(), 0);
    }

    // Change to the new org, causing a full refresh of the application
    selectOrg(orgId: uuid) {
        this.windowService.navigateTo(this.getOrgHref(orgId));
    }

    getOrgHref(orgId: uuid): string {
        const queryParams = { orgId: orgId };
        const tree = this.router.createUrlTree([],
            { queryParams, queryParamsHandling: "merge" });
        return this.router.serializeUrl(tree);
    }
}
