import { Component, ViewChild } from "@angular/core";
import { AuthStateService } from "@app2/account/auth-state.service";
import { UserService } from "@app2/account/user.service";
import { Person } from "@app2/type-defs/user/user-types";
import { PrDropdownComponent } from "@app2/shared/components/pr-dropdown.component";
import { Observable } from "rxjs";

@Component({
    selector: "navbar-user-menu",
    template: `
        <pr-dropdown align="right"
                     [closeOnClick]="false"
                     test-id="navbar-user-menu-dropdown">
            <div pr-dropdown-trigger class="button-dropdown">
                <span class="user-icon-container">
                    <i class="fas fa-circle-user"></i>
                </span>
            </div>
            <ul pr-dropdown-body>
                <li class="user-item"
                    test-id="current-user-name">
                    {{ (currentUser$ | async)?.fullName }}
                </li>
                <li pr-dropdown-close-on-click
                    dsRouterLink="/settings/profile"
                    test-id="view-profile-option">
                    {{ "VIEW_PROFILE" | localize }}
                </li>
                <li>
                    <inactivity-timeout-toggle></inactivity-timeout-toggle>
                </li>
                <li>
                    <shortcut-keys-toggle></shortcut-keys-toggle>
                </li>
                <li *featureGate="'readonlyToggle'">
                    <readonly-toggle></readonly-toggle>
                </li>
                <li *dsPermissionsRequired="'TRAC_USER'">
                    <dark-mode-toggle></dark-mode-toggle>
                </li>
                <li (click)="logOut()">
                    {{ "LOG_OUT" | localize }}
                </li>
            </ul>
        </pr-dropdown>
    `,
    styles: `
        @import "/src/styles/colors";
        @import "/src/styles/settings";

        .user-icon-container {
            font-size: 1.5rem;

            .fa-circle-user {
                color: $lara-purple;
            }
        }

        .user-item {
            font-weight: $font-weight-demibold;
            text-transform: uppercase;
            pointer-events: none;
            margin: 4px 0;
        }
    `,
})
export class NavbarUserMenuComponent {
    currentUser$: Observable<Person>;
    // This is used from a test
    @ViewChild(PrDropdownComponent) dropdown: PrDropdownComponent;

    constructor(private readonly authStateService: AuthStateService,
                userService: UserService) {
        this.currentUser$ = userService.getCurrentUser$();
    }

    logOut() {
        this.authStateService.logOut();
    }
}
