import { StoragePeriod } from "@app2/shared/type-defs";

export interface PersonSummary {
    id: uuid;
    fullName: string;
    shortName: string;
    active: boolean;
    homeOrgId: uuid;
    roleId: uuid;
    emails: string[];
    phoneNumber: string;
    phoneNumbers: PhoneNumber[];
    groupTicketEmails: boolean;
}

export interface DataRestriction {
    id: uuid;
    name: string;
    description: string;
    blacklistQueryString: string;
    whitelistQueryString: string;
    createdOn: date;
    createdBy: uuid;
    modifiedOn: date;
    modifiedBy: uuid;
    deleted: boolean;
    memberPersonIds: uuid[];
}

export interface OrgSettings {
    emailTimezone: string;
    status: OrgStatusType;
}

export enum OrgStatusType {
    ONBOARDING = "ONBOARDING",
    CTS_OPS_MANAGED = "CTS_OPS_MANAGED",
    CLIENT_MANAGED =  "CLIENT_MANAGED",
    DEPARTING = "DEPARTING",
}

export const SKU_REPORTING_PREMIUM = "REPORTING_PREMIUM";
export const CYBERFRAUD_STANDARD = "CYBERFRAUD_STANDARD";
export const CYBERFRAUD_PREMIUM = "CYBERFRAUD_PREMIUM";

export const SKU_RISK_INFORMATION_SECURITY = "RISK_INFORMATION_SECURITY";
export const SKU_RISK_SYSTEMS_AND_APPLICATIONS = "RISK_SYSTEMS_AND_APPLICATIONS";
export const SKU_RISK_THIRD_PARTY_RISK = "RISK_THIRD_PARTY_RISK";

export const SKU_ACCELERATED_ARCHIVE_DATA_TIER = "ACCELERATED_ARCHIVE_DATA_TIER";
export const SKU_CUSTOM_PARSERS = "CUSTOM_PARSERS";

export type ProductSku = typeof CYBERFRAUD_STANDARD
    | typeof CYBERFRAUD_PREMIUM
    | "FRAMEWORK_CIS_CONTROLS_SKU"
    | "FRAMEWORK_CYBER_PROFILE_SKU"
    | "FRAMEWORK_NIST_CYBER_SECURITY_SKU"
    | typeof SKU_REPORTING_PREMIUM
    | typeof SKU_RISK_INFORMATION_SECURITY
    | typeof SKU_RISK_SYSTEMS_AND_APPLICATIONS
    | typeof SKU_RISK_THIRD_PARTY_RISK
    | typeof SKU_ACCELERATED_ARCHIVE_DATA_TIER
    | typeof SKU_CUSTOM_PARSERS;

export interface OrgSummary {
    id: uuid;
    name: string;
    google2faRequired: boolean;
    currentState: string; //TODO: seems an invalid field
    liveStoragePeriod: StoragePeriod;
    coldStoragePeriod: StoragePeriod;
    productSkus: ProductSku[];
}

export interface Person {
    id: uuid;
    emails: Email[];
    fullName: string;
    shortName: string;
    phoneNumber: string;
    phoneNumbers: PhoneNumber[];
    active: boolean;
    orgId: uuid;
    roleId: uuid;
    google2faEnabled: boolean;
    u2fEnabled: boolean;
    authorizedOrgIds: uuid[];
    notes: string;
    // This is only ever populated for SCIM-owned users, it goes through MS Azure AD flows.
    externalId?: string;
    groupTicketEmails: boolean;
    dataRestrictionId?: uuid;
    jobDescriptorIds: uuid[];
}

export interface PhoneNumber {
    id: uuid;
    type: PhoneNumberType;
    countryCode: string;
    areaCode: string;
    number: string;
    extension: string;
}

export type PhoneNumberType = "MAIN" | "OFFICE" | "MOBILE" | "EMERGENCY" | "OTHER";

export interface NewOrgUser {
    email: string;
    fullName: string;
    shortName: string;
    phoneNumber: string;
    phoneNumbers: PhoneNumber[];
    notes: string;
    externalId: string;
    roleId: uuid;
    jobDescriptorIds: uuid[];
}

export interface Email {
    id: uuid;
    email: string;
    active: boolean;
    verified: boolean;
}

export interface TwoFactorDetails {
    google2faInitialized: boolean;
    google2faUri: string;
    u2fInitialized: boolean;
    webAuthnAuthenticationRequest: string;
}

export interface Role {
    id: uuid;
    name: string;
    description?: string;
    shared: boolean;
    permissionNames: string[];
    createdOn: date;
    createdBy?: uuid;
    modifiedOn: date;
    modifiedBy?: uuid;
    deleted: boolean;
}

export interface JobDescriptor {
    id: uuid;
    name: string;
    description: string;
    isModifiable: boolean;
    deleted: boolean;
    createdOn: date;
    createdBy?: uuid;
    modifiedOn: date;
    modifiedBy?: uuid;
}

export interface KnowledgeBaseLogin {
    loginUrl: string;
}

export interface SsoEnabledUrl {
    ssoEnabled: boolean;
    ssoName: string;
    ssoUrl: string;
    samlRequest: string;
}


export type InfoBannerType = "success" | "info" | "warning" | "alert";

export interface InfoBanner {
    id: uuid;
    type: InfoBannerType;
    title: string;
    message: string;
    hyperlink: string;
    showFrom: date;
    showUntil: date;
}

export type ExternalTokenType = "API" | "INSTALLER" | "AGENT" | "EXTERNAL";

export interface AwsInfo {
    readonly accessKeyId: string;
    readonly accessKey: string;
    readonly sqsQueue: string;
}

export interface ExternalToken {
    id: uuid;
    key: string;
    secret: string;
    description: string;
    type: ExternalTokenType;
    expired: boolean;
    createdOn: date;
    createdBy: uuid;
    awsInfo?: AwsInfo;
}

export interface OrgCredentials {
    id: uuid;
    key: string;
    secret: string;
    description: string;
    type: ExternalTokenType;
    expired: boolean;
    createdOn: date;
    createdBy: uuid;
    queueName: string;
    praesidioKey: string;
    praesidioSecret: string;
    awsKey: string;
    awsSecret: string;
}

export function toExternalToken(orgCredential: OrgCredentials): ExternalToken {
    return {
        id: orgCredential.id,
        key: orgCredential.key,
        secret: orgCredential.secret,
        description: orgCredential.description,
        type: orgCredential.type,
        expired: orgCredential.expired,
        createdOn: orgCredential.createdOn,
        createdBy: orgCredential.createdBy,
        awsInfo: {
            sqsQueue: orgCredential.queueName,
            accessKey: orgCredential.awsSecret,
            accessKeyId: orgCredential.awsKey,
        },
    };
}
