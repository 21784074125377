import { booleanAttribute, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";

@Component({
    selector: "search-box",
    template: `
        <form class="search-filter"
              [autocomplete]="autocomplete">
            <input type="text" name="query"
                   [(ngModel)]="searchTextCopy"
                   [placeholder]="placeholderKey | localize"
                   (keydown.enter)="submitForm()"
                   (ngModelChange)="reportEveryValueChange && submitForm()"
                   test-id="search-box-input"/>
            <button type="button" test-id="search-box-button"
                    (click)="submitForm()">
                <i class="far fa-search"></i>
            </button>
        </form>
    `,
    // TODO: refactor this component's CSS, we shouldn't add this override here, not only because `style` has a very
    // high priority, but because this margin won't work for every possible usage of this component.
    // After refactoring, cleanup all the overrides we're doing to bypass this, and remove the need to have this
    // component in a `header`
    host: {
        style: "margin-left: auto;",
    },
})
export class SearchBoxComponent implements OnChanges {
    @Input() searchText: string = "";
    @Input() placeholderKey: string;
    @Input() autocomplete: string;
    // Use this input if you want this component to report every change to the input's text, even if the submit button
    // or enter key have not been pressed
    @Input({ transform: booleanAttribute }) reportEveryValueChange: boolean = false;

    @Output() searchTextChange = new EventEmitter<string>();

    searchTextCopy: string;

    ngOnChanges() {
        this.searchTextCopy = this.searchText;
    }

    submitForm() {
        this.searchTextChange.emit(this.searchTextCopy);
    }
}
