import { Component, Input, OnInit } from "@angular/core";
import { AssetClientService } from "@app2/clients/asset-client.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { Software } from "@app2/type-defs/assets/assets-types";
import { getMessageForError } from "@app2/util/errors/handleable-errors";

@Component({
    selector: "asset-software-list",
    template: `
        <table class="nice-form hover-table software-table full-width"
               *dsLoading="loading">
            <thead>
            <tr>
                <th class="hover-tab full-width"></th>
                <th class="click-target"
                    (click)="selectOrToggleSort('name')"
                    test-id="sort-by-name">
                    {{ 'SOFTWARE_NAME' | localize }}
                    <sort-order-arrows fieldName="name"
                                       [currentField]="sortBy"
                                       [currentDesc]="sortDesc"></sort-order-arrows>
                </th>
                <th class="small-cell center">
                    {{ 'SOFTWARE_VERSION' | localize }}
                </th>
                <th class="timestamp-cell center click-target"
                    (click)="selectOrToggleSort('lastSeen')"
                    test-id="sort-by-lastSeen">
                    {{ 'SOFTWARE_LAST_SEEN' | localize }}
                    <sort-order-arrows fieldName="lastSeen"
                                       [currentField]="sortBy"
                                       [currentDesc]="sortDesc"></sort-order-arrows>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let sw of allSoftware">
                <td class="hover-tab full-width"></td>
                <td test-id="software-name">
                    {{ sw.name }}
                </td>
                <td class="small-cell center" test-id="software-version">
                    {{ sw.version }}
                </td>
                <td class="timestamp-cell center" test-id="software-lastSeen">
                    <tooltip-date [for]="sw.lastSeen"></tooltip-date>
                </td>
            </tr>
            <tr *ngIf="allSoftware.length === 0">
                <td class="no-data-text" colspan="4">{{ 'ASSET_NO_SOFTWARE' | localize }}</td>
            </tr>
            </tbody>
        </table>
    `,
    styles: `
        .software-table {
            margin-right: auto;
            margin-left: auto;
        }
    `,
})
export class AssetSoftwareListComponent implements OnInit {
    @Input() assetId: uuid;

    allSoftware: Software[];
    sortBy: string = "name";
    sortDesc = false;
    loading: boolean;

    constructor(private assetClient: AssetClientService,
                private notificationsService: NotificationsService) {
    }

    ngOnInit() {
        this.loading = true;
        this.assetClient.getAssetSoftware(this.assetId)
            .then(software => {
                this.allSoftware = software;
                this.updateSoftwareList();
            })
            .catch(error => {
                const message = getMessageForError(error.error, "ERROR_LOADING_SOFTWARE");
                this.notificationsService.showError(message, error);
            })
            .finally(() => this.loading = false);
    }

    selectOrToggleSort(fieldName: string) {
        if (this.sortBy === fieldName) {
            this.sortDesc = !this.sortDesc;
        } else {
            this.sortBy = fieldName;
            this.sortDesc = true;
        }
        this.updateSoftwareList();
    }

    private updateSoftwareList() {
        const allSoftware = this.allSoftware.sort((a, b) => {
            const fieldA = a[this.sortBy] || "";
            const fieldB = b[this.sortBy] || "";

            return fieldA.toLowerCase().localeCompare(fieldB.toLowerCase());
        });

        this.allSoftware = this.sortDesc ? allSoftware.reverse() : allSoftware;
    }
}
