import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import Editor from "@toast-ui/editor";

@Component({
    selector: "ds-rich-text-editor",
    template: `<div #editor></div>`
})
export class DsRichTextEditorComponent implements AfterViewInit, OnDestroy {
    @Input() content: string;
    @Input() placeholder: string;
    @Input() height: number;
    @ViewChild("editor") editorElement: ElementRef;

    @Output() contentChange: EventEmitter<string> = new EventEmitter<string>();

    private editor: Editor;

    ngAfterViewInit() {
        this.editor = new Editor({
            el: this.editorElement.nativeElement,
            initialEditType: "markdown",
            placeholder: this.placeholder,
            toolbarItems: [
                ["heading", "bold", "italic"],
                ["ul", "ol", "indent", "outdent"],
                ["table", "link"],
                ["quote", "code"]
            ],
            usageStatistics: false,
            height: this.height + "px",
        });

        this.editor.setMarkdown(this.content);

        this.editor.on("change", () => {
            this.contentChange.emit(this.htmlSanitizedContent());
        });
    }

    public htmlSanitizedContent(): string {
        return this.editor.getMarkdown()
            .replace(/<br>\n/g, "<br>");
    }

    public importTemplate(templateBody: string): void {
        this.content = this.content?.length === 0
            ? templateBody
            : `${ templateBody }\n\n${ this.content }`;
        this.editor.setMarkdown(this.content);
    }

    public clearContent() {
        this.content = "";
        this.editor.setMarkdown(this.content);
    }

    public setContent(content: string) {
        this.content = content;
        this.editor.setMarkdown(this.content);
    }

    ngOnDestroy() {
        this.editor.destroy();
    }
}
