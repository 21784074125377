import { isEqual } from "lodash-es";

function transformObject(obj: any, transformer: (name: string) => string, exclude: string[] = []): any {
    if (!obj || isEqual(exclude, ["*"])) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(x => transformObject(x, transformer, exclude));
    }

    if (typeof obj !== "object") {
        return obj;
    }

    const entries = Object.entries(obj)
        .map(([name, value]) => {
            if (typeof value === "object" && !exclude.includes(name)) {
                value = transformObject(value, transformer, exclude);
            }
            return [transformer(name), value];
        });
    return Object.fromEntries(entries);
}

/**
 * Transforms object property names from snake to camel case.
 *
 * Note: This can be harmful to objects with mixed casing, use exclude to protect non-snake property names.
 *
 * @param {*} snakeObj - the original object with snake-cased property names
 * @param {string|string[]=} exclude - either a string property name or an array of string property names
 *  the _children_ of which will not be modified
 * @returns {*} the input object with all property names converted from snake to camel case
 */
export function snakeToCamel(snakeObj: any, exclude?: string | string[]): any {
    if (typeof exclude === "string") {
        exclude = [exclude];
    }
    return transformObject(snakeObj, fieldNameToCamel, exclude);
}

export function fieldNameToCamel(str) {
    return str.toLowerCase()
        .replace(/_(.)/g, (match, group1) => group1.toUpperCase())
        .replace(/@/, "");
}

/**
 * Transforms object property names from camel to snake case.
 *
 * Note: This can be harmful to objects with mixed casing, use exclude to protect non-camel property names.
 *
 * @param {*} camelObj the original object with camel-cased property names
 * @param {string|string[]=} exclude either a string property name or an array of string property names
 *  the _children_ of which will not be modified
 * @returns {*} the input object with all property names converted from camel to snake case
 */
export function camelToSnake(camelObj: any, exclude?: string | string[]): any {
    if (typeof exclude === "string") {
        exclude = [exclude];
    }
    return transformObject(camelObj, fieldNameToSnake, exclude);
}

export function fieldNameToSnake(str: string) {
    return str.replace(/([A-Z])/g, (match, group1) => "_" + group1.toLowerCase());
}
